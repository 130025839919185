import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default function About({data}) {
  return (
    <Layout>

      <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/about" />
      </Helmet>



      <h1>About {data.site.siteMetadata.title}</h1>
      <img src="https://i.postimg.cc/LXmP9Pj8/cheeplus-logo.png" width="256" />
      <p>
      We are a small team located in New Zealand. We want to create some fun games and useful applications for people over the world.
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`